// routes.js
import React from 'react';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import Program from './components/Program';
import Services from './components/Services';


export const routes = [
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/about',
    element: <AboutUs />,
  },
  {
    path: '/services',
    element: <Services />,
  },
  {
    path: '/program',
    element: <Program />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
];