import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CarouselItem from './util/CarouselItem';

function AboutUs() {

  useEffect(() => {
    document.title = 'Ποιοί είμαστε';
  }, []);

  const carouselData=[
    { imageSrc: 'ngym5.jpg' , caption:  ' ' },
    { imageSrc: 'gym.jpg' , caption:  'Οι εγκαταστάσεις μας' },
    { imageSrc: 'gym2.jpg' , caption:  'Οι εγκαταστάσεις μας' },
    { imageSrc: 'gym3.jpg' , caption:  'Οι εγκαταστάσεις μας' },
    { imageSrc: 'gym4.jpg' , caption:  'Οι μαθητές μας' },
    { imageSrc: 'gym5.jpg' , caption:  'Οι εγκαταστάσεις μας' },
    { imageSrc: 'gym6.jpg' , caption:  'Οι εγκαταστάσεις μας' },
    { imageSrc: 'gym7.jpg' , caption:  'Οι μαθητές μας' },
    { imageSrc: 'gym8.jpeg' , caption:  '' },
    { imageSrc: 'ngym.jpg' , caption:  'Οι εγκαταστάσεις μας' },
    { imageSrc: 'ngym3.jpg' , caption:  'Οι εγκαταστάσεις μας' },
    { imageSrc: 'ngym4.jpg' , caption:  'Οι εγκαταστάσεις μας' },


  ]

  return (
    <>
   
  <div className="container-fluid">
        <CarouselItem data={carouselData} />
      </div>
    { <Container className="container-with-margin">
      <Row>
        <Col md={6}>
        <div>

          <h1 className="theme-color">ΣΠΥΡΟΣ ΚΟΥΡΟΥΚΛΗΣ</h1>
          <h2>Διπλωματούχος Προπονητής ΓΓ. Αθλητισμού</h2>
          <div style={{ marginTop: "20px" }}>
          <p style={{fontStyle: "italic"}}
              >Σας καλωσορίζω στον Αθλητικό Σύλλογο Κ1 Δαναός, έναν χώρο όπου η κάθε στιγμή μπορεί να γίνει αφορμή για εξέλιξη και επίτευξη των προσωπικών σας στόχων!</p>
          <p>
          Γεννήθηκα στις 23 Ιουλίου 1969 και από τότε, η ζωή μου είναι στενά συνυφασμένη με τον  κόσμο της γυμναστικής και πιο ειδικά, του Kickboxing. Με πάθος και αφοσίωση προς  τον αθλητισμό, είχα το όνειρο να δημιουργήσω έναν χώρο όπου οι άνθρωποι θα μπορούν να εκπαιδευτούν, να εξελίσσονται και να απολαμβάνουν τα οφέλη της γυμναστικής. 
          </p>
          <p>
          Το 2007, το όνειρο αυτό έγινε πραγματικότητα όταν ίδρυσα τον Αθλητικό Σύλλογο Κ1 Δαναός, με στόχο τη δημιουργία ενός χώρου φιλικού προς όλους, όπου ο καθένας μπορεί να βρει το αθλητικό πρόγραμμα που ταιριάζει στις δικές του ανάγκες και στόχους.
          </p>
          <p>
          Πιστεύω ότι ο αθλητισμός μας αφορά όλους και θα πρέπει να είναι προσβάσιμος για όλες τις ηλικίες και τα επίπεδα δεξιοτήτων με βάση τα προσωπικά ενδιαφέροντα του καθενός. Έτσι, προσφέρουμε ποικιλία προγραμμάτων, όπως Pilates, Body Pump, TRX, Personal Training, και πολλά άλλα, με στόχο να προωθήσουμε τη γενική ευεξία και τη φυσική κατάσταση.
          </p>
          <p>
          Το παιδικό τμήμα αποτελεί μια επίσης σημαντική διάσταση του συλλόγου μας. Παρέχουμε ένα ασφαλές και κοινωνικό περιβάλλον για τους μικρούς μας φίλους, ώστε να αθληθούν, να αναπτύξουν τις δεξιότητές τους και να αγαπήσουν τον αθλητισμό από μικρή ηλικία.
          </p>
          <p> Στον Αθλητικό Σύλλογο Κ1 Δαναός είμαστε κάτι παραπάνω απο ένα γυμναστήριο. Είμαστε μια κοινότητα! Σας περιμένουμε να γίνετε μέλη της!</p>
          </div>
          </div>
        </Col>
        <Col md={6}>
          <img src="me.jpg" alt="Company " className="img-fluid"/>
        </Col>
      </Row>
      
    </Container> }
    </>
  );
}


export default AboutUs;