import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';

const GymTimetable = () => {
  useEffect(() => {
    document.title = "Πρόγραμμα";
  }, []);

  // Sample timetable data
  const timetableData = [
    {
      day: 'ΔΕΥΤΕΡΑ',
      timetable: [
        { time: '08:30 - 09:30', activity: 'Body Pump (Σοφία)' },
        { time: '12:00 - 13:00', activity: 'Personal Group' },
        { time: '17:00 - 18:00', activity: 'Παιδικό Kick Boxing' },
        { time: '18:00 - 19:00', activity: 'Personal Group TRX' },
        { time: '19:00 - 20:00', activity: 'Body Pump (Νατάσα)' },
        { time: '20:00 - 21:00', activity: 'Kick Boxing' }
      ],
    },
    {
      day: 'ΤΡΙΤΗ',
      timetable: [
        { time: '08:30 - 09:30', activity: 'Cross Training (Σπύρος)' },
        { time: '12:00 - 13:00', activity: 'Personal Group' },
        { time: '17:00 - 18:00', activity: 'TRX Group' },
        { time: '19:00 - 20:00', activity: 'Personal Group TRX' },
        { time: '20:00 - 21:00', activity: 'Pilates (Άννα)' }
      ],
    },
    {
      day: 'ΤΕΤΑΡΤΗ',
      timetable: [
        { time: '08:30 - 09:30', activity: 'Cross Training (Σοφία)' },
        { time: '12:00 - 13:00', activity: 'Personal Group' },
        { time: '17:00 - 18:00', activity: 'Παιδικό Kick Boxing' },
        { time: '19:00 - 20:00', activity: 'Μυϊκή Ενδυνάμωση (Σοφία)' },
        { time: '20:00 - 21:00', activity: 'Kick-Boxing' }
      ],
    },
    {
      day: 'ΠΕΜΠΤΗ',
      timetable: [
        { time: '08:30 - 09:30', activity: 'Feet & Abs (Σπύρος)' },
        { time: '12:00 - 13:00', activity: 'Personal Group' },
        { time: '17:00 - 18:00', activity: 'TRX Group' },
        { time: '20:00 - 21:00', activity: 'Personal Group' }
      ],
    },
    {
      day: 'ΠΑΡΑΣΚΕΥΗ',
      timetable: [
        { time: '12:00 - 13:00', activity: 'Personal Group' },
        { time: '17:00 - 18:00', activity: 'Παιδικό Kick Boxing' },
        { time: '18:00 - 19:00', activity: 'Personal Group' },
        { time: '19:00 - 20:00', activity: 'Cross Training (Σπύρος)' },
        { time: '20:00 - 21:00', activity: 'Kick-Boxing' }
      ],
    },
  ];

 
// Helper function to generate timeframes in 24-hour format starting at 08:30
const generateTimeframes = () => {
  const timeframes = ['08:30 - 09:30'];
  for (let i = 10; i <= 20; i++) { // Start at 9:00 and finish at 23:00
    const startTime = `${i < 10 ? '0' : ''}${i}:00`;
    const endTime = `${(i + 1) < 10 ? '0' : ''}${i + 1}:00`;
    timeframes.push(`${startTime} - ${endTime}`);
  }
  return timeframes;
};


  // Helper function to get the program for a specific timeframe
  const getProgramForTimeframe = (timetable, timeframe) => {
    const program = timetable.find((item) =>
      item.time === timeframe
    );
    return program ? program.activity : '';
  };

  return (
    <div style={{ height: '100%'}} className="program-bg-image flex-grow-1 d-flex">
      <div className="container mt-4">
      <h2 className="text-center mb-4" style={{color:'white'}}>ΠΡΟΓΡΑΜΜΑ 2023-2024</h2>
      <Table
      responsive
      className="mt-3 mb-5 "
      style={{  border: '2px solid #DEE2E6' , boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)', opacity: '0.9'}}
    >
       <thead className="table-header text-center" >
          <tr>
            <th> ΩΡΑ</th>
            {timetableData.map((item) => (
              <th key={item.day}>{item.day} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {generateTimeframes().map((timeframe) => (
            <tr key={timeframe}>
              <td style={{fontWeight:'bold', background: 'linear-gradient(180deg, rgba(233, 236, 239, 0) 0%, rgba(233, 236, 239, 0.5) 100%)', borderBottom:'none'}} className="text-center">{timeframe}</td>
              {timetableData.map((item) => (
                <td key={item.day} className="text-center" style={{fontWeight:'bold'}}>
                  {getProgramForTimeframe(item.timetable, timeframe)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>

      </ Table>
    </div>

    </div>
  );
};

export default GymTimetable;
