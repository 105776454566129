import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="mt-auto py-4" style={{ minHeight: 120, backgroundColor: '#ff6700' }}>
      <Container className='d-flex flex-column justify-content-between align-items-center'>
        <div>
          <span style={{ fontSize: '20px', color: 'white', marginRight:'10px'}}>ΑΚΟΛΟΥΘΗΣΤΕ ΜΑΣ</span>
          <a href="https://www.instagram.com/k1danaos/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-instagram" style={{ fontSize: '30px', color: 'white', marginRight: '10px' }}></i>
          </a>
          <a href="https://www.facebook.com/pages/%CE%B3%CF%85%CE%BC%CE%BD%CE%B1%CF%83%CF%84%CE%AE%CF%81%CE%B9%CE%BF%20%22kourouklis%22%CE%B1.%CF%83.%20%CE%9A1%20%CE%B4%CE%B1%CE%BD%CE%B1%CF%8C%CF%82/170922846303927/" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-facebook" style={{ fontSize: '30px', color: 'white' }}></i>
          </a>
        </div>
        <div>
          <p className="text-center" style={{ fontSize: '15px', color: 'white', marginTop:"30px" }}>
            Copyright © 2023 Αθλητικός Σύλλογος Κ1 Δαναός
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;

