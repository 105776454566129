import React from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMap = () => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const center = { lat: 37.83597, lng: 23.91939 };
  const zoom = 16.5;

  // Reference size at zoom level 16.5 (your desired size)
  const referenceSize = { width: 100, height: 50 };

  // Calculate marker size based on zoom level
  const markerSize = {
    width: referenceSize.width * (1 / (2 ** (16.5 - zoom))),
    height: referenceSize.height * (1 / (2 ** (16.5 - zoom))),
  };

  const AnyReactComponent = () =>  (<div
    style={{
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      width: `${markerSize.width}px`,
      height: `${markerSize.height}px`,
      textAlign: 'center',
      zIndex: 100,
    }}
    >
    <img
      src="pin.png"
      alt="Custom Marker"
      style={{ width: '100%', height: '100%' }}
    />
    </div>);

  return (
    <div style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={center.lat} lng={center.lng} />

        {/* Custom image marker */}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
