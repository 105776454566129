import React from 'react';
import { Carousel } from 'react-bootstrap';

function CarouselItem({ data }) {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: '3%' , paddingBottom: '2%'}}>
      <Carousel style={{ width: '80%', maxWidth: '1000px' }}>
        {data &&
          data.map((item) => (
            <Carousel.Item key={item.id} interval={1000}>
              <img
                className="d-block w-100"
                src={item.imageSrc}
                alt="Gym"
                style={{ minWidth: 320, minHeight: 240 , maxHeight: '500px', margin: '0 auto' }} 
              />
              <Carousel.Caption className="d-none d-md-block" style={{ width: '44%', margin: '0 auto' }}>
                <h3>{item.caption}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}

export default CarouselItem;
