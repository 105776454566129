import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

function Dashboard() {
  useEffect(() => {
    document.title = "Αρχική";
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to update the window width
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    document.title = "Πρόγραμμα";

    // Add a resize event listener to update the window width
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const fontSize = windowWidth > 1000 ? 100 : 60;
  const fontSizeAdd = windowWidth > 1000 ? 80 : 50;
  const fontSizeGet = windowWidth > 1000 ? 25 : 18;


  return (
    <>
      <Container fluid style={{ paddingLeft: 0, paddingRight: 0,  minHeight: 700, background: 'url("main2.jpeg") no-repeat center fixed',   backgroundSize: 'cover'}}>
        <div
          style={{
            position: "relative",
            top: 300,
            transform: "translateY(-50%)",
            color: "white",
            textAlign: "left",
          }}
        >
          <h2 style={{ marginLeft: 30, fontSize: fontSizeAdd, fontWeight: "bold" }}>ADDICTED</h2>
          <p style={{  marginLeft: 30, fontSize: fontSizeGet}}>TO GETTING</p>
          <h1
            className="theme-color"
            style={{ marginLeft: 30,fontSize: fontSize, fontWeight: "bold" }}
          >
            STRONGER
          </h1>
        </div>
        <div
          style={{
            position: "relative",
            color: "white",
            textAlign: "center",
            top:  280,
          }}
        >
          <p style={{ fontSize: "30px", fontWeight: "bold"}}>
            Γυμναστική / Ομαδικά Πραγράμματα / Kick-Boxing / Personal Training
          </p>
        </div>
      </Container>

      <Container className="container-with-margin">
        <Row>
          <Col md={6}>
            <h1 className="theme-color" style={{textAlign:'center'}}>ΑΘΛΗΤΙΚΟΣ ΣΥΛΛΟΓΟΣ Κ1 ΔΑΝΑΟΣ</h1>
            <div style={{ marginTop: "20px" }}>
              <p
                style={{
                  fontStyle: "italic",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Κάθε ατομική εμπειρία είναι η δική σας μοναδική ιστορία
                επιτυχίας.
              </p>
              <p>
                Από τον Νοέμβριο του 2007 μέχρι σήμερα, στις αθλητικές μας
                εγκαταστάσεις έχουμε δώσει το στίγμα μας στο χώρο της άθλησης.
                Μένοντας πιστοί στη φιλοσοφία μας ότι η γυμναστική και η υγεία
                δεν πρέπει να είναι πολυτέλεια στη ζωή μας, στα χρόνια
                λειτουργίας μας παρέχουμε υψηλής ποιότητας υπηρεσίες σε προσιτές
                τιμές.
              </p>
              <p>
                Στελεχώνοντας το γυμναστήριο μας με έμπειρους και καταρτισμένους
                γυμναστές αφοσιωμένους στο έργο τους, με ομαδικά προγράμματα και
                personal μαθήματα, δίνοντας τον καλύτερο εαυτό τους στο
                γυμναστήριο μας, ΜΑΖΙ θα βρούμε τον ιδανικό τρόπο εκγύμνασης που
                σας ταιριάζει!
              </p>
            </div>
          </Col>
          <Col md={6}>
            <img src="gym.jpg" alt="Company" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
