import React, { useEffect } from 'react';

function Services() {
  useEffect(() => {
    document.title = 'Οι υπηρεσίες μας';
  }, []);

  const servicesData = [
    {
      id: 1,
      title: 'Kick Boxing',
      description: 'Το Kickboxing είναι ένα δυναμικό, εντυπωσιακό και απαιτητικό άθλημα που συνδυάζει την πυγμαχία με τα λακτίσματα. Πρόκειται για μια πολύ δημοφιλή πολεμική τέχνη που είναι κατάλληλη τόσο για άνδρες όσο και για γυναίκες, καθώς και για ανθρώπους όλων των ηλικιών και επιπέδων φυσικής κατάστασης.',
      imageUrl: 'boxing.jpg',
    },
    {
      id: 2,
      title: 'Pilates',
      description:
        'Το Pilates είναι ένα ολοκληρωμένο σύστημα εκγύμνασης που περιλαμβάνει ασκήσεις διάτασης και ενδυνάμωσης, εκτελούμενες είτε στο έδαφος είτε με χρήση ειδικών εξαρτημάτων, όπως μπάλες και λάστιχα. Είναι κατάλληλο για όλες τις ηλικίες και επίπεδα φυσικής κατάστασης.',
      imageUrl: 'pilates.jpg',
    },
    {
      id: 3,
      title: 'Personal training',
      description:
        'Είτε θέλεις να κάνεις τα πρώτα σου βήματα στη γυμναστική, είτε δεν βλέπεις αποτελέσματα από την γυμναστική που κάνεις, ένας personal trainer μπορεί να σου δώσει όλα εκείνα τα εφόδια για να πετύχεις τους στόχους σου.',
      imageUrl: 'personal.jpg',
    },
    {
      id: 4,
      title: 'Body Pump',
      description:
        'Το Bodypump είναι ένα πολύ δημοφιλές πρόγραμμα που συνδυάζει ελεύθερα βάρη για μείωση του σωματικού λίπους, μυϊκή ενδυνάμωση, και βελτίωση της φυσικής κατάστασης σε σύντομο χρονικό διάστημα. ',
      imageUrl: 'bodypump.jpg',
    },
    {
      id: 5,
      title: 'Cross training',
      description:
        'Το cross-training, επίσης γνωστό ως διασταυρούμενη προπόνηση, είναι μια προηγμένη μέθοδος εκγύμνασης όπου εναλλάσσονται ασκήσεις αερόβιες και αναερόβιες, καθώς και οι μύες που χρησιμοποιούνται κάθε φορά. Αυτή η ποικιλόμορφη προπόνηση ενισχύει τη γενική αντοχή και τη δύναμη σε όλο το σώμα.',
        imageUrl: ' crosstraining.jpg',
    },
    {
      id: 6,
      title: 'TRX',
      description:
        'Tο TRX είναι μια ειδική μέθοδος προπόνησης με ιμάντες που χρησιμοποιεί τη βαρύτητα και το βάρος του σώματός, για την ανάπτυξη δύναμης, ισορροπίας, συντονισμού, ευλυγισίας και σταθερότητας του κορμού και των αρθρώσεων.',
      imageUrl: 'trx.jpg',
    },
    {
      id: 7,
      title: 'Hips and abs',
      description:
        'Hips & Abs είναι ένα δυναμικό πρόγραμμα γυμναστικής που επικεντρώνεται στους κοιλιακούς, τους ραχιαίους, και τους γλουτούς μυς. Συμπεριλαμβάνει ασκήσεις με το βάρος του σώματος και χρήση εξοπλισμού, συνοδευόμενες από στρώμα.',
      imageUrl: 'hipsabs.jpg',
    },
    {
      id: 8,
      title: 'Zumba',
      description:
        'Η Zumba είναι διασκεδαστική προπόνηση που συνδυάζει αερόβια άσκηση με χορό. Κατάλληλη για όλους, προάγει τη φυσική κατάσταση, τον συντονισμό και τη θετική διάθεση.',
      imageUrl: 'zumba.jpg',
    },
  ];

  return (
    <div className="container" style={{ marginBottom: '50px', marginTop: '50px'  }}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-center mb-4 theme-color">ΟΙ ΥΠΗΡΕΣΙΕΣ ΜΑΣ</h2>
          <p style={{ fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center' }}>
          Βρείτε το πρόγραμμα γυμναστικής που σας ταιριάζει!
            </p>
        </div>
      </div>
      <div className="row">
        {servicesData.map((service) => (
          <div key={service.id} className="col-md-3">
            <div className="service-box text-center" >
              <img src={service.imageUrl} alt={service.title} className="circular-image" />
              <h3>{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
