import React, { useEffect, useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';


function NavbarMenu() {
  const [activeLink, setActiveLink] = useState("/");

  useEffect(() => {
    // Get the current path from the URL on initial load
    const currentPath = window.location.pathname;
    setActiveLink(currentPath);
  }, []);

  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <>
      <Navbar
        style={{
          borderBottom: "2px solid #ff6700",
          backgroundColor: "#ff6700",
          minHeight: "30px",
        }}
        className="text-white"
        expand="lg"
      >
        <Container className="justify-content-space-between" style={{ height: 30 , display: 'flex' , flexDirection: 'row' , flexWrap: 'wrap'}}>
            <div style={{ height: 30 , overflow: "hidden"}}>
              <Navbar.Brand style={{ cursor: "default" }}>
                <i
                  className="bi bi-house-fill fs-10 me-1"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {" "}
                </i>{" "}
                <span style={{ fontSize: "12px", color: "white" }}>
                  {" "}
                  Λεωφόρος Καλυβίων - Λαγονησίου, Καλύβια Θορικού, 19010{" "}
                </span>
              </Navbar.Brand>
              <Navbar.Brand style={{ cursor: "default" }}>
                <i
                  className="bi bi-telephone-fill fs-10 me-1"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {" "}
                </i>{" "}
                <span style={{ fontSize: "12px", color: "white" }}>
                  {" "}
                  2299 046236{" "}
                </span>
              </Navbar.Brand>
              <Navbar.Brand style={{ cursor: "default" }}>
                <i
                  className="bi bi-envelope-fill fs-10 me-1 "
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {" "}
                </i>{" "}
                <span style={{ fontSize: "12px", color: "white" }}>
                  {" "}
                  k1danaos.gym@gmail.com{" "}
                </span>
              </Navbar.Brand>
            </div>

            <div>
              <Navbar.Brand style={{ cursor: "default" }}>
                <i
                  className="bi bi-calendar fs-10 me-1 "
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {" "}
                </i>{" "}
                <span style={{ fontSize: "12px", color: "white" }}>
                  {" "}
                  ΔΕΥ - ΠΑΡ: 08:00 - 22:30 ΣΑΒ: 09:00 – 18:00{" "}
                </span>
              </Navbar.Brand>
          </div>
        </Container>
      </Navbar>

      {/* Second Layer Navbar */}
      <Navbar
        style={{ borderBottom: "2px solid #DEE2E6", minHeight: "80px" }}
        className="bg-light-subtle bg-gradient my-navbar"
        expand="lg"
      >
        <Container>
          <Navbar.Brand href="/">
            <img src="/logo.jpg" alt="K1Danaos Logo" width="80" height="80" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar" />
          <Navbar.Collapse id="navbar" className="justify-content-end">
          <Nav className="mr-auto">
            <Link className={activeLink === '/'?'active':''} to="/" style={{ color: 'black', textDecoration: 'none', marginRight: 10 }}  onClick={() => handleNavLinkClick('/')}>ΑΡΧΙΚΗ</Link>
            <Link className={activeLink === '/about'?'active':''} to="/about" style={{ color: 'black', textDecoration: 'none' , marginRight: 10 }}  onClick={() => handleNavLinkClick('/about')} > ΠΟΙΟΙ ΕΙΜΑΣΤΕ</Link>
            <Link className={activeLink === '/services'?'active':''} to="/services" style={{ color: 'black', textDecoration: 'none' , marginRight: 10 }}  onClick={() => handleNavLinkClick('/services')} >ΥΠΗΡΕΣΙΕΣ</Link>
            <Link className={activeLink === '/program'?'active':''} to="/program" style={{ color: 'black', textDecoration: 'none', marginRight: 10   }}  onClick={() => handleNavLinkClick('/program')} >ΠΡΟΓΡΑΜΜΑ</Link>
            <Link className={activeLink === '/contact'?'active':''} to="/contact" style={{ color: 'black',  textDecoration: 'none'  }}  onClick={() => handleNavLinkClick('/contact')} >ΕΠΙΚΟΙΝΩΝΙΑ</Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarMenu;
